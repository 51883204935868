import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

if (process.env.REACT_APP_ENV === "production") {
	window.CONFS = {
		apiServer: 'https://service.ddmt.education',
		storageUrl: "https://cloud.ddmt.education/prod",
		adminAxiosSetting: {},
		memberAxiosSetting: {}
	}
}else if (process.env.REACT_APP_ENV === "development") {
	window.CONFS = {
		apiServer: 'https://test.service.ddmt.education',
		storageUrl: "https://test.cloud.ddmt.education",
		adminAxiosSetting: {},
		memberAxiosSetting: {}
	}
}else{
	window.CONFS = {
		apiServer: 'http://127.0.0.1:23369',
		// apiServer: 'https://test.service.ddmt.education',
		storageUrl: "http://127.0.0.1:8000",
		// apiServer: 'https://service.ddmt.education',
		// storageUrl: "https://cloud.ddmt.education/prod",
		adminAxiosSetting: {},
		memberAxiosSetting: {}
	}
}
console.log({"window.CONFS":window.CONFS})
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
