import React, { Component } from 'react'
import './ApprovedMemberLessonPlans.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import PlanDetail from '../../Management/LessonPlans/PlanDetail'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';

class ApprovedMemberLessonPlans extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lessonPlans: [],
			loading: false,
			openModal: false
		}

		this.memberGUID = this.props.match.params.GUID ? this.props.match.params.GUID : this.props.match.params.id		

		this.columns = [
			{ field: 'title', headerName: '標題', flex: 1 },
			{
				field: 'displayCreatedAt', headerName: '建立', width: 150, renderCell: (params) => (
					<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
				)
			},
			{
				field: 'displayModifiedAt', headerName: '更新', width: 150, renderCell: (params) => (
					<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
				)
			},
			{
				field: 'status', headerName: '狀態', width: 150, renderCell: (params) => (
					<>{params.value.waitingApprove && <div>待審核</div>}{params.value.allApprove && <div>已通過</div>}</>
				)
			},
			{
				field: 'displayApplyApproveAt', headerName: '動作', width: 150, renderCell: (params) => (
					<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
				)
			},
			{
				field: 'statusBtn', headerName: ' ', width: 150, renderCell: (params) => (
					<>
						{params.value.waitingApprove ?
							<button onClick={() => this.props.history.push(`/admin/membership/approved_members/${this.memberGUID}/${params.value._id}`)} className="approveBtn">審核</button> :
							<button onClick={() => this.props.history.push(`/admin/membership/approved_members/${this.memberGUID}/${params.value._id}`)} className="readBtn">查看</button>
						}
						{params.value.allApprove &&
							<button onClick={() => { this.cancelApprovedLessonplan(params.value._id) }} className="cancelBtn">取消</button>
						}
					</>
				)
			}
		]
	}

	componentDidMount = () => {
		this.searchLessonPlansByGUID()
		if (this.props.match.params.id) {
			this.setState({openModal: true, lessonPlanContent: <PlanDetail loginRole="admin" /> })
			console.log("00000000")
		}
	}

	componentDidUpdate(prevProps) {
		console.log("111111111")
		if (this.props.location !== prevProps.location) {
			console.log("2222222")
			if (this.props.match.params.GUID) {
				this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: true })
			}
			else {
				this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: false })
			}
		}
	}

	searchLessonPlansByGUID = () => {
		this.setState({ loading: true }, () => {
			this.searchLessonPlansByGUIDAction().then(lessonplans => {
				lessonplans.forEach(lessonplan => {
					lessonplan.id = lessonplan._id
					lessonplan.displayCreatedAt = new Date(lessonplan.createdAt)
					lessonplan.displayModifiedAt = new Date(lessonplan.modifiedAt)
					lessonplan.displayApplyApproveAt = new Date(lessonplan.applyApproveAt)
					lessonplan.status = {
						waitingApprove: lessonplan.waitingApprove,
						allApprove: lessonplan.allApprove
					}
					lessonplan.statusBtn = {
						_id: lessonplan._id,
						waitingApprove: lessonplan.waitingApprove,
						allApprove: lessonplan.allApprove
					}
				})
				this.setState({ "lessonPlans": lessonplans, loading: false })
			})
		})
	}

	searchLessonPlansByGUIDAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/lessonplans/' + this.memberGUID)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	cancelApprovedLessonplan = (lessonplanId) => {
		if (!window.confirm("確定要取消審核通過?"))
			return false
		return new Promise(resolve => {
			axios.patch(`${window.CONFS.apiServer}/admin/lessonPlan/cancelapproved/${lessonplanId}`)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						let lessonplans = this.state.lessonPlans.filter((item) => {
							return item._id !== lessonplanId
						})
						console.log(lessonplans)
						this.setState({ "lessonPlans": lessonplans })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "80%",
				top: "20px"
			}
		})(Dialog);
		return (
			<div className="ApprovedMemberLessonPlans">
				<div className="MemberLessonPlans__content">
					{this.state.loading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							rows={this.state.lessonPlans}
							columns={this.columns} />
						// <table>
						// 	<thead>
						// 		<tr>
						// 			<td>標題</td>
						// 			<td style={{ "width": "150px" }}>建立</td>
						// 			<td style={{ "width": "150px" }}>更新</td>
						// 			<td style={{ "width": "80px" }}>狀態</td>
						// 			<td style={{ "width": "150px" }}>動作</td>
						// 			<td style={{ "width": "110px" }}></td>
						// 		</tr>
						// 	</thead>
						// 	{this.state.loading ?
						// 		<tbody>
						// 			<tr>
						// 				<td className="loading" colSpan="3">
						// 					<Loader
						// 						type="Oval"
						// 						color="#7f1083"
						// 						height={22}
						// 						width={22}
						// 						visible={true}
						// 					/>
						// 				</td>
						// 			</tr>
						// 		</tbody> :
						// 		<tbody>
						// 			{this.state.lessonPlans.map((item, k) => {
						// 				return (<tr key={k}>
						// 					<td>{item.title}</td>
						// 					<td>
						// 						<Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
						// 					</td>
						// 					<td>
						// 						<Moment format="YYYY/MM/DD HH:mm">{item.modifiedAt}</Moment>
						// 					</td>
						// 					<td>
						// 						{item.waitingApprove &&
						// 							<div>待審核</div>
						// 						}
						// 						{item.allApprove &&
						// 							<div>已通過</div>
						// 						}
						// 					</td>
						// 					<td>
						// 						{item.applyApproveAt &&
						// 							<Moment format="YYYY/MM/DD HH:mm">{item.applyApproveAt}</Moment>
						// 						}
						// 					</td>
						// 					<td>
						// 						{item.waitingApprove ?
						// 							<button onClick={() => this.props.history.push(`/admin/approvedmember/${this.memberGUID}/${item._id}`)} className="approveBtn">審核</button> :
						// 							<button onClick={() => this.props.history.push(`/admin/approvedmember/${this.memberGUID}/${item._id}`)} className="readBtn">查看</button>
						// 						}
						// 						{item.allApprove &&
						// 							<button onClick={()=> {this.cancelApprovedLessonplan(item._id)}} className="cancelBtn">取消</button>
						// 						}
						// 					</td>

						// 				</tr>)
						// 			})
						// 			}
						// 		</tbody>
						// 	}
						// </table>

					}
				</div>
				{/* <ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.props.history.push(`../${this.memberGUID}`) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "80%", "height": "85vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.props.history.push(`../${this.memberGUID}`) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.lessonPlanContent}
				</ReactModal> */}
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openModal}
					onClose={() => { this.props.history.push(`../${this.memberGUID}`) }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push(`../${this.memberGUID}`) }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.lessonPlanContent}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(ApprovedMemberLessonPlans);